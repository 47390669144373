<template>
  <v-stepper v-model="e1" vertical>
    <v-stepper-step :complete="e1 > 0" step="0">Requisitos Para el Registro</v-stepper-step>
    <v-stepper-content step="0">
      <div v-html="requisitos"></div>
      <v-btn color="error" @click="showConfirmacion('Cerrar')" class="mr-2" small>Cancelar</v-btn>
      <v-btn color="primary" @click="e1 = 1" small>ACEPTAR y COntinuar</v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="e1 > 1" step="1">Actualización de Direcciones</v-stepper-step>
    <v-stepper-content step="1">
      <Direcciones :onlyActive="true" />
      <v-btn color="error" class="mr-2" small @click="showConfirmacion('Cerrar')">Cancelar</v-btn>
      <v-btn color="primary" @click="e1 = 2" small>Continuar</v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e1 > 2"
      step="2"
    >Registro de Formaciones Academicas {{cantidadFormaciones}}</v-stepper-step>
    <v-stepper-content step="2">
      <Formaciones :actions="false" :select="true" @formacionSelecionada="seleccionarFormaciones" />
      <v-btn text @click="e1 = 1" small>Atras</v-btn>
      <v-btn color="error" class="mr-2" small @click="showConfirmacion('Cerrar')">Cancelar</v-btn>
      <v-btn color="primary" @click="e1 = 3" small>continuar</v-btn>
    </v-stepper-content>

    <v-stepper-step step="3">Registro de Experiencias Laborales {{cantidadExperiencia}}</v-stepper-step>
    <v-stepper-content step="3">
      <Experiencias :actions="false" :select="true" @datosSeleccionados="seleccionarExperiencias" />
      <v-btn text @click="e1 = 1" small>atras</v-btn>
      <v-btn color="error" class="mr-2" small @click="showConfirmacion('Cerrar')">Cancelar</v-btn>
      <v-btn color="primary" @click="e1 = 4" small>continuar</v-btn>
    </v-stepper-content>

    <v-stepper-step step="4">Registro de Defensa de Causas {{cantidadCausas}}</v-stepper-step>
    <v-stepper-content step="4">
      <Causas :actions="false" :select="true" @datosSeleccionados="seleccionarCausas" />
      <v-btn text @click="e1 = 1" small>atras</v-btn>
      <v-btn color="error" class="mr-2" small @click="showConfirmacion('Cerrar')">Cancelar</v-btn>
      <v-btn
        color="primary"
        @click="showConfirmacion('RegistrarDefensor')"
        small
      >guardar</v-btn>
    </v-stepper-content>
    <Confirmacion
      :pregunta="confirmacion.consultaDialog"
      :consulta="confirmacion.preguntaConsulta"
      :loading="confirmacion.loadingPregunta"
      @si="confirmacionAceptada"
      @no="confirmacion.consultaDialog=false"
    />
  </v-stepper>
</template>
<script>
import Confirmacion from "@/common/util/Confirmacion";
export default {
  components: {
    Formaciones: () => import("@/components/Formacion"),
    Experiencias: () => import("@/components/Experiencia"),
    Direcciones: () => import("@/components/Direcciones"),
    Causas: () => import("@/components/DefensaCausa"),
    Confirmacion
  },
  mounted() {
    if (!this.isRegistered) {
      this.$router.push({ name: "Home" });
    }
  },
  data() {
    return {
      e1: 0,
      requisitos:
        '<div class="mb-5"><div class="title">Registrarse en RUANNA</div><div class="v-subheader theme--light">Requisitos</div><div class="subtitle-2 grey--text"><ul><li>Verifica o Actualiza la información de Direccion de tu Domicilio Procesal</li><li>Registra o Selecciona tu Formacion Academica</li><li>Registra o Selecciona tu Experiencia Laboral</li><li>Registra Defensa de Causas</li></ul></div></div>',
      defensor: {
        experiencias: [],
        formaciones: [],
        causas: [],
        tipo: null
      },
      confirmacion: {
        consultaDialog: false,
        preguntaConsulta: "",
        tipoConsulta: null,
        loadingPregunta: false
      },
      tipoDefensa: this.$store.getters.getParameter("tipo_defensor", "RUANNA")
    };
  },
  computed: {
    cantidadFormaciones() {
      return this.defensor.formaciones.length;
    },
    cantidadExperiencia() {
      return this.defensor.experiencias.length;
    },
    cantidadCausas() {
      return this.defensor.causas.length;
    }
  },
  methods: {
    showConfirmacion(tipo) {
      this.confirmacion.tipoConsulta = tipo;
      if (tipo == "Cerrar") {
        this.confirmacion.preguntaConsulta =
          "¿ Estas Seguro de Cancelar tu registro <strong>RUANNA</strong>?.";
      }
      if (tipo == "RegistrarDefensor") {
        this.confirmacion.preguntaConsulta = `¿ Estas Seguro de Confirmar tu registro <strong>RUANNA</strong> con el siguiente contenido registrado ?. <br/> <ul><li>Cantidad de Formaciones ${this.cantidadFormaciones}</li><li>Cantidad de Experiencias ${this.cantidadExperiencia}</li><li>Cantidad de Defensa de Causa ${this.cantidadCausas}</li></ul>`;
      }
      this.confirmacion.consultaDialog = true;
    },
    confirmacionAceptada() {
      if (this.confirmacion.tipoConsulta == "Cerrar") {
        this.$router.push({ name: "Home" });
      }
      if (this.confirmacion.tipoConsulta == "RegistrarDefensor") {
        this.guardarRegistroDefensor();
      }
    },
    seleccionarFormaciones(data) {
      this.defensor.formaciones = data;
    },
    seleccionarExperiencias(data) {
      this.defensor.experiencias = data;
    },
    seleccionarCausas(data) {
      this.defensor.causas = data;
    },
    guardarRegistroDefensor() {
      this.confirmacion.loadingPregunta = true;
      this.defensor.tipo = this.tipoDefensa.value;
      if (this.defensor.tipo != null) {
        this.$http
          .post(`${this.$apiUrl}abogado-ciudadano/defensor`,this.defensor)
          .then(response => {
            this.confirmacion.loadingPregunta = false;
          })
          .catch(error => {
            this.confirmacion.loadingPregunta = false;
          });
      } else {
        this.confirmacion.loadingPregunta = false;
        this.$notify({
          group: "foo",
          type: "error",
          title: "Error de la Aplicacion",
          text: "Faltan parametros de la session"
        });
      }
    }
  }
};
</script>
<style>
form .direccion-activa {
  border-left: 5px solid #34bfa3 !important;
}
form .direccion-inactiva {
  border-left: 5px solid #f4516c !important;
}
</style>