<template>
<v-row justify="center">
    <v-dialog v-model="pregunta" persistent max-width="450">
      <v-card>
        <v-card-title class="headline"><v-icon size="50" class="mr-5">mdi-alert-outline</v-icon> Confirmar</v-card-title>
       <v-card-text>
         <div v-html="consulta" class="text-justify"></div>
         <slot name="extra"></slot>
       </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if="!cerrar">
            <v-btn text color="error darken-1"  @click="cancelar" :disabled="loading">cancelar</v-btn>
            <v-btn color="success darken-1"  @click="aceptar" :loading="loading">ACEPTAR</v-btn>
          </template>
           <v-btn v-else color="default" flat @click="cancelar">cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default{
  name:'Confirmacion',
  props: {
    pregunta: {
        type:Boolean,
        default:false
        },
    consulta: {
      type:String,
      default:'¿ Estas Seguro de Realizar Esta Accion ?.'
        },
    loading: {
      type: Boolean,
      default: false
    },
    cerrar:{
      type:Boolean,
    }
  },
  methods: {
    cancelar() {
      this.$emit("no", false);
    },
    aceptar() {
      this.$emit("si", true);
    }
  }
};
</script>
<style scoped>
.v-card__title.headline {
    color: #0d1627;
    background-color: #e0e0e0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
}
</style>
